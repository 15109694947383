@charset "utf-8";
/* CSS Document */
@media (max-width:767px) {
	.hidden-xs {
		display:none!important
	}
}
@media (min-width:767px) {
	.visible-xs {
		display:none!important
	}
}
/* @-ms-viewport{width:device-width}.visible-lg,.visible-md,.visible-sm,.visible-xs {
	display:none!important
} */

* {
	margin: 0;
	padding: 0;
}

.rootHomePage {
	overflow: hidden;
}

.rootHomePage div,table {
	font-family: Arial;
	font-size: 13px;
}
.rootHomePage body {
	position: relative;
}
.rootHomePage a, a:visited {
	padding-left: 15px;
	color: #ffffff!important;
	text-decoration: underline;
}
.rootHomePage a:hover {
	color: #FBCF00!important;
	text-decoration: underline;
}


.rootHomePage .top {
    background: #886245 url(images/bg.png) no-repeat;
	background-size: cover;
	width: 100%;
	height: 600px;
}
.rootHomePage .top-logo {
	width: 100%;
	height: 100px;
	display: flex;
	align-items: center;
}
.rootHomePage .logo {
	padding: 20px 0 0 20px;
	font-size: 20px;
	color: #fff;
	line-height: 60px;
	display: inline-block;
}
.rootHomePage .logo img {
	width: 48px;
	height: 48px;
}
.rootHomePage .name {
	display: inline-block;
	margin-left: 10px;
	vertical-align: center;
}
.rootHomePage .banner {
	width: 100%;
	height: 500px; 
}
.rootHomePage .banner-left {
	display: inline-block;
	width: 50%;
	text-align: right;
}
.rootHomePage .banner-left img {
	height: 500px;
}
.rootHomePage .banner-right {
	display: inline-block;
	height: 500px; 
	width: 45%;
	vertical-align: top;
}
.rootHomePage .icon {
	text-align: center;
	padding-top: 60px;
}
.rootHomePage .icon img {
	width: 88px;
}
.rootHomePage .slogan {
	text-align: center;
	display: block;
}
.rootHomePage .slogan img {
	width: 100%;
	padding: 40px 0;
}
.rootHomePage .title {
	text-align: center;
	font-size: 14px;
	color: #fff;
	padding: 10px 0 20px;
	
}
.rootHomePage .mail {
	text-align: center;
	font-size: 16px;
	color: #fff;
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	padding: 20px 0;
	
}
.rootHomePage .start {
	width: 1280px;
	height: auto;
	padding-top: 30px;
	margin: 0 auto;
	background: url(images/1.png) no-repeat;
}
.rootHomePage .start-left {
	display: inline-block;
	width: 50%;
	vertical-align: top;
	text-align: center;
	padding-top: 100px;
}
.rootHomePage .start-left h2 {
	font-size: 48px;
}
.rootHomePage .start-left .slogan img {
	width: 80%;
}
.rootHomePage .start-right {
	display: inline-block;
	width: 45%;
	text-align: center;
}
.rootHomePage .main {
	width: 1280px;
	height: 440px;
	padding-top: 40px;
	margin: 0 auto;
}
.rootHomePage .main-text {
	display: inline-block;
	width: 50%;
	vertical-align: top;
	text-align: left;
	padding-top: 120px;
	font-size: 16px;
	font-family: Arial;
}
.rootHomePage .main-text h2 {
	font-size: 30px;
	font-weight: 500;
}
.rootHomePage .main-text p {
	margin-top: 40px;
	font-size: 16px;
}
.rootHomePage .main-pic {
	display: inline-block;
	width: 45%;
	text-align: center;
}
.rootHomePage .home {
	height: auto;
	background: #fcfcfc url(images/2.png) no-repeat;
}
.rootHomePage .profile {
	height: auto;
	background: url(images/3.png) no-repeat;
}
.rootHomePage .profile-text {
	padding-left: 80px;
	width: 45%;
}
.rootHomePage .details {
	height: auto;
	background: #fcfcfc url(images/4.png) no-repeat bottom;
}
.rootHomePage .share {
	background: url(images/5.png) no-repeat;
}

.rootHomePage .container {
	width: 1280px;
	margin: 0 auto;
}
.rootHomePage .bottom {
	background: #000000;
	padding: 30px 0;
}
.rootHomePage .bottom-logo {
	width: 48%;
	display: inline-block;
}
.rootHomePage .contact {
	width: 51%;
	text-align: right;
	display: inline-block;
	color: #fff;
	vertical-align: middle;
}
.rootHomePage .contact span {
	padding-left: 15px;
}
.rootHomePage .ico-email {
	background: url(images/ico-email.svg) no-repeat left center;
}
.rootHomePage .ico-tel {
	background: url(images/ico-tel.svg) no-repeat left;
}
.rootHomePage .ico-download {
	background: url(images/ico-download.svg) no-repeat left;
	margin-right: 15px;
}
.rootHomePage .contact-space {
	padding-right: 30px;
}
.rootHomePage .line {
	border-top: #333333 1px solid;
	margin-top: 30px;
}
.rootHomePage .copyright {
	width: 48%;
	height: 40px;
	color: #fff;
	line-height: 40px;
	display: inline-block;
	padding-top: 10px;
	
}
.rootHomePage .link {
	width: 51%;
	height: 40px;
	line-height: 40px;
	text-align: right;
	display: inline-block;
	padding-top: 10px;
	vertical-align: top;
}
.rootHomePage .download {
	display: flex;
}
.rootHomePage .download a img {
    cursor: pointer;
}
.rootHomePage .download p {
	padding-top: 25px;
}
.rootHomePage .download-left {
	width: 48%;
	display: inline-block;
	text-align: right;
	padding-right: 20px;
}
.rootHomePage .download-right {
	width: 48%;
	display: inline-block;
	text-align: left;
	padding-left: 20px;
}
.rootHomePage .download-right p {
	text-align: left;
	margin-left: -15px!important;
}
.rootHomePage .download-phone {
	width: 100%;
	display: inline-block;
	text-align: center;
	padding-right: 20px;
}
.rootHomePage .download-phone p {
	margin-left: -15px!important;
}
/* #ios, #android {
	display: none;
} */
.rootHomePage #backtop { 
	position:fixed; 
	right: 20px; 
	bottom: 20px;
	width: 48px;
	height: 48px;
	background: url(images/top.svg) center;
	cursor: pointer;
}




/* 小屏样式 */
@media (max-width: 1024px) {
  .rootHomePage .logo {
	line-height: 60px;
  }
  .rootHomePage .logo img {
	width: 60px;
  }
  .rootHomePage .banner {
  	padding-bottom: 0;
  }
  .rootHomePage .banner img {
  	width: 100%;
  }
  .rootHomePage .slogan img {
  	width: 80%;
  }
  .rootHomePage .title {
  	font-size: 18px;
  }
  .rootHomePage .copyright {
  	position: relative;
  	font-size: 14px;
  }

}

@media (max-width: 720px) {
	.rootHomePage .top {
		background: #886245 url(images/bg.png) no-repeat center;
		background-size: cover;
		width: 100%;
		height: 520px;
	}
	.rootHomePage .container {
		width: 100%;
		margin: 0 auto;
	}
	.rootHomePage .logo {
		line-height: 48px;
		font-size: 18px;
	}
	.rootHomePage .logo img {
		width: 48px;
	}
	.rootHomePage .banner-left {
		display: none;
	}
	.rootHomePage .banner-right {
		display: block;
		height: 300px; 
		width: 100%;
	}
	.rootHomePage .icon {
		display: none;
	}
	.rootHomePage .slogan {
		padding: 0;
	}
	.rootHomePage .slogan img {
		width: 80%!important;
	}
	.rootHomePage .title {
		font-size: 12px;
		padding-top: 10px;
	}
	.rootHomePage .banner {
		padding: 0 0 10px;
		margin-top: -20px;
	}
	.rootHomePage .banner img {
		width: 100%;
	}
	.rootHomePage .start {
		width: 100%;
		height: auto;
		padding-top: 30px;
	}
	.rootHomePage .start-left {
		display: inline-block;
		width: 100%;
		vertical-align: top;
		text-align: center;
		padding-top: 100px;
	}
	.rootHomePage .start-left h2 {
		font-size: 40px;
	}
	.rootHomePage .start-left .slogan img {
		width: 60%;
	}
	.rootHomePage .start-right {
		display: block;
		width: 100%;
		text-align: center;
	}
	.rootHomePage .start-right img {
		width: 50%;
	}
	.rootHomePage .main {
		width: 100%;
		height: auto;
		padding-top: 30px;
		margin: 0 auto;
	}
	.rootHomePage .main-text {
		width: 100%;
		text-align: center;
		padding-top: 20px;
		font-family: Arial;
	}
	.rootHomePage .main-text p {
		margin: 20px 0 40px;
		font-size: 16px;
		font-family: Arial;
	}
	.rootHomePage .main-pic {
		width: 100%;
		text-align: center;
	}
	.rootHomePage .main-pic img {
		width: 50%;
	}
	.rootHomePage .home {
		background: #f9f9f9 url(images/2.png) no-repeat center bottom;
	}
	.rootHomePage .profile {
		background: url(images/3.png) no-repeat center bottom;
	}
	.rootHomePage .profile-text {
		padding-left: 0;
		width: 100%;
	}
	.rootHomePage .details {
		background: #f9f9f9 url(images/4.png) no-repeat center bottom;
	}
	.rootHomePage .bottom-logo {
		width: 100%;
		display: inline-block;
		padding-bottom: 30px;
		padding-left: 0;
		text-align: center;
	}
	.rootHomePage .contact {
		width: 100%;
		text-align: center;
		display: inline-block;
		padding-right: 0;
		color: #fff;
		line-height: 28px;
	}
	.rootHomePage .download {
		margin-right: 0!important;
	}
	.rootHomePage .ico-email {
		margin-left: -4px;
	}
	.rootHomePage .copyright {
		width: 100%;
		height: 40px;
		color: #fff;
		line-height: 40px;
		text-align: center;
		display: inline-block;
		margin-top: 0;
		margin-left: 0;
		padding-top: 10px;
	}
	.rootHomePage .link {
		width: 100%;
		height: 40px;
		line-height: 40px;
		text-align: center;
		display: inline-block;
		margin-top: 0;
		margin-right: 0;
		border-top: none;
	}
	.rootHomePage .mail {
		font-size: 14px;
	}
	.rootHomePage .download img {
		width: 130px;
	}

}